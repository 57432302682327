import EzComponent from '@frontend/components/EzComponent';
import { connect } from 'react-redux'

import '@frontend/components/basic-components/chat-button/index.less';
export class MobileParser extends EzComponent {
    render() {
        const { children,isMobile } = this.props;
        const mobile                = isMobile.isMobile
        return (
            <>
                {
                    mobile &&
                    children
                }
            </>
        );
    }
}

function mapStateToProps(isMobile) {
    return {
        isMobile
    }
}
export default connect(mapStateToProps, {})(MobileParser);
